<template>
  <div>
    <div v-if="!to">
      <a
        :href="href"
        :class="getClass"
        :style="`white-space: nowrap; width: ${width}; height: ${height}`"
        @click="handleClick"
      >
        <loading
          :active="getLoaderState"
          :height="25"
          :opacity="0"
          :color="getLoaderColor"
          :loader="`bars`"
          :is-full-page="false"
          :class="`outline-none`"
        />
        <div :class="{ invisible: isLoading }">
          <slot v-if="!onlyShowIcon">
            {{ text }}
          </slot>
          <slot v-if="onlyShowIcon" name="icon"> </slot>
        </div>
      </a>
    </div>
    <div v-else>
      <router-link
        :to="to"
        :class="getClass"
        :style="`white-space: nowrap; width: ${width}; height: ${height}`"
        @click="handleClick"
      >
        <slot>{{ text }}</slot>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnchorButton',
  props: {
    text: {
      required: false,
    },
    onlyShowIcon: {
      required: false,
    },
    to: {
      required: false,
    },
    href: {
      required: false,
      default: 'javascript:void(0);',
    },
    variant: {
      required: false,
      default: 'primary',
      validator(val) {
        return [
          'primary',
          'secondary',
          'success',
          'success-alt',
          'warning',
          'warning-alt',
          'warning-light',
          'disabled',
        ].includes(val)
      },
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    height: {
      type: String,
      required: false,
      default: '36px',
    },
  },
  computed: {
    getClass: function() {
      const base =
        'relative flex items-center justify-center border border-transparent text-center px-4 font-semibold text-14px leading-normal rounded transition ease-in-out duration-150 cursor-pointer focus:outline-none'
      const textWhite = ` text-gray-100 hover:text-white focus:text-white`
      const textDark = ` text-gray-800 hover:text-gray-900 focus:text-gray-900`

      const variants = {
        'primary': `${base} ${textWhite} bg-gray-900 hover:bg-gray-800 hover:border-gray-800 focus:bg-gray-700`,
        'secondary': `${base} ${textDark} bg-gray-200 hover:border-gray-900 hover:text-gray-900 focus:bg-gray-300`,
        'success': `${base} ${textWhite} bg-oGreen hover:border-green-600 hover:opacity-75 focus:border-green-500`,
        'success-alt': `${base} bg-green-alt-button text-oGreen hover:text-green-600 focus:text-green-600 hover:border-green-600 focus:border-green-500`,
        'warning': `${base} ${textWhite} bg-oOrange hover:bg-orange-200 hover:border-orange-200 focus:bg-orange-300`,
        'warning-alt': `${base} text-gray-800 ${textWhite} bg-gray-200 hover:border-oOrange hover:bg-oOrangeAlt focus:bg-oOrange `,
        'warning-light': `${base} border-red-600  text-red-500 hover:text-red-600 focus:text-red-600 hover:bg-red-100 focus:border-red-500`,
        'disabled': `cursor-not-allowed text-gray-400 hover:text-gray-500 border-gray-200 ${base}  bg-gray-100 hover:border-gray-300 focus:bg-gray-200`,
      }

      if (this.disabled) return variants['disabled']

      return this.variant in variants
        ? variants[this.variant]
        : variants['primary']
    },
    getLoaderState() {
      return (
        (!this.href || this.href === 'javascript:void(0);') && this.isLoading
      )
    },
    getLoaderColor() {
      if (this.variant === 'secondary') {
        return '#101828'
      } else if (this.variant === 'success-alt') {
        return '#09aa35'
      } else if (this.variant === 'warning-alt') {
        return '#101828'
      } else if (this.variant === 'warning-light') {
        return '#fb2c36'
      } else {
        return '#fff'
      }
    },
  },
  methods: {
    handleClick() {
      if (this.isLoading === false && this.disabled === false) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-green-alt-button {
  background-color: #d9f0d8;
}
.bg-orange-button {
  background-color: #ff5500;
}
</style>
